'use client';

import { NotFoundSvg } from '@/components/design/assets/svg/index.svg';
import { Button } from 'flowbite-react';
import React from 'react';
import { useRouter } from 'next/navigation';

export default function NotFound() {
  const router = useRouter();

  function goHome() {
    router.push('/en');
  }
  return (
    <section className="bg-white h-[100vh] dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="h-[80vh] flex flex-col justify-center items-center">
          <NotFoundSvg />
          <h1 className="my-3 text-3xl font-extrabold text-primary-600 dark:text-primary-500">
            404 Page Not Found.
          </h1>
          <p className="mb-3 text-gray-400 dark:text-white">
            Whoops. Not our best swing. Please email
          </p>
          <p className="mb-4 text-gray-400 dark:text-gray-400">
            <a className={'text-blue-800'} href="mailto:contact@golfliveapp.com">contact@golfliveapp.com</a> for help
          </p>
          <Button color="dark" className={'w-[400px]'} onClick={() => goHome()}>Back home</Button>
        </div>
      </div>
    </section>
  );
}
